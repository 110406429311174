import React, { useCallback, useContext } from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import { RemoveScroll } from "react-remove-scroll";
import Zoom from "@material-ui/core/Zoom";
import { htmlSafe, wrapTableTags } from "modules/utils";

import * as Button from "components/Button";
import PlatformContext from "modules/platform/context";
import ModalOverlay from "../Overlay";

import * as styles from "./styles.module.scss";
import { ButtonStyle } from "components/Button/constants";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

export type ModalInfoTemplateProps = {
  onClose?: () => void;
  text?: string | JSX.Element;
  title: string;
};

export const ModalInfoTemplate = React.memo<ModalInfoTemplateProps>(({ onClose, text, title }) => {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <section className={styles.modalInfo} role="dialog">
      <header>
        <h2>{title}</h2>
        <Button.Generic onClick={handleClose} ariaLabel="Close" />
      </header>
      <div>
        {typeof text === "string" ? <p>{htmlSafe(text)}</p> : text}
        <Button.Generic onClick={handleClose} ariaLabel="Close" label="Close" style={ButtonStyle.BRAND_BLACK_GRAPE} />
      </div>
    </section>
  );
});

export type ModalInfoProps = {
  buttonLabel?: string;
  destroyModal: () => void;
  disableRestoreFocus?: boolean;
  hideModal: () => void;
  id: string;
  onClose?: () => void;
  open: boolean;
  text?: string | JSX.Element;
  title: string;
};

const Info: React.FunctionComponent<ModalInfoProps> = ({
  destroyModal,
  disableRestoreFocus,
  hideModal,
  id,
  onClose,
  open,
  text,
  title,
}) => {
  const { isMobile } = useContext(PlatformContext);

  const handleClose = useCallback(() => {
    onClose ? open && onClose() : open && hideModal();
  }, [hideModal, onClose, open]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Enter") {
        handleClose();
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [handleClose]
  );

  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay
        disableRestoreFocus={disableRestoreFocus}
        onExited={destroyModal}
        onKeyDown={handleKeyDown}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}
      >
        <ModalInfoTemplate onClose={handleClose} text={text} title={title} />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default Info;
