import React from "react";

import { DefaultMetaType } from "modules/meta/types";

type MetaCustomProps = {
  defaultMeta: DefaultMetaType;
};

export const metaCustom = ({ defaultMeta }: MetaCustomProps) => {
  return defaultMeta?.head_assets.map((i, key: any) => {
    const safeInlineScript = i.script_inline && `${i.script_inline.content}`;
    const safeExternalScript = i.script_external && `${i.script_external.src}`;
    const externalScript = i.script_external ? <script async={false} key={key} src={safeExternalScript} /> : null;
    const inlineScript = i.script_inline ? (
      <script async={false} key={key}>
        {safeInlineScript}
      </script>
    ) : (
      externalScript
    );
    const script = inlineScript ? inlineScript : externalScript;
    const link = i.link ? <link key={key} rel={i.link.rel} href={i.link.href} /> : null;
    return script ? script : link;
  });
};
