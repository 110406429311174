import React, { useContext } from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import { RemoveScroll } from "react-remove-scroll";
import PlatformContext from "modules/platform/context";

import Slide from "@material-ui/core/Slide";
import { ModalFormProps } from "./types";
import ModalOverlay from "../Overlay";
import * as Button from "components/Button";
import CrossSVG from "modules/theme/icons/general/cross.svg";

import * as styles from "./styles.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalForm: React.FC<Omit<ModalFormProps, "destroyModal" | "open">> = ({
  onCancel,
  renderBodyContent,
  renderHeaderContent,
}) => {
  return (
    <div className={styles.modalForm}>
      <header>
        <div>
          {renderHeaderContent()}
          <Button.Generic onClick={onCancel} ariaLabel="Close form popup">
            <CrossSVG />
          </Button.Generic>
        </div>
      </header>
      <div>{renderBodyContent()}</div>
    </div>
  );
};

const Form: React.FC<ModalFormProps> = ({ destroyModal, onCancel, open, ...props }) => {
  const { isMobile, isMobileOnly } = useContext(PlatformContext);
  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay
        fullScreen={isMobileOnly}
        fullWidth={true}
        onClose={onCancel}
        onExited={destroyModal}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}
      >
        <ModalForm onCancel={onCancel} {...props} />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default Form;
