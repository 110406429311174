import React from "react";
import clsx from "clsx";

import * as Button from "components/Button";

type BurgerMenuButtonProps = {
  handleMobileMenuClick: () => void;
  toggleMobileMenu: boolean;
};

const BurgerMenuButton: React.FC<BurgerMenuButtonProps> = ({ handleMobileMenuClick, toggleMobileMenu }) => {
  const className = clsx("burgerMenuButton", toggleMobileMenu && "activeMobileMenu");

  return (
    <Button.Generic className={className} ariaLabel="Toggle menu" onClick={handleMobileMenuClick}>
      <span></span>
    </Button.Generic>
  );
};

export default BurgerMenuButton;
