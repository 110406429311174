import { Dispatch } from "modules/redux/store";
import { v4 as uuidv4 } from "uuid";

import { ModalActionDestroy, ModalActionHide, ModalActionInsert } from "./types";
import { ModalAction } from "./constants";
import { Modal } from "./types";

export function modalActionShow(modal: Omit<Modal, "id" | "open">): (dispatch: Dispatch) => string {
  return (dispatch: Dispatch) => {
    (modal as Modal).id = uuidv4();
    (modal as Modal).open = true;
    dispatch(modalActionInsert(modal as Modal));
    return (modal as Modal).id;
  };
}

export const modalActionDestroy = (id: string): ModalActionDestroy => ({
  type: ModalAction.DESTROY,
  id: id,
});

export const modalActionHide = (id: string): ModalActionHide => ({
  type: ModalAction.HIDE,
  id: id,
});

export const modalActionInsert = (modal: Modal): ModalActionInsert => ({
  type: ModalAction.INSERT,
  modal: modal,
});
