import React, { PropsWithChildren } from "react";
import { ThemeProvider, makeStyles, unstable_createMuiStrictModeTheme } from "@material-ui/core/styles";
import { TransitionProps } from "@material-ui/core/transitions";
import MuiDialog from "@material-ui/core/Dialog";
import { Theme } from "@material-ui/core";

interface StyleProps {
  paper: boolean;
}

const theme = unstable_createMuiStrictModeTheme();

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    willChange: "transform",
  },
  backdropRootClass: {
    backgroundColor: "rgba(255,255,255, 0.75)",
    backdropFilter: "blur(16px) brightness(0.72)",
  },
  paper: ({ paper }) =>
    !paper
      ? {
          backgroundColor: "transparent",
          borderRadius: "0px",
          boxShadow: "none",
          border: "none",
        }
      : {
          backgroundColor: "transparent",
          boxShadow: "none",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 0 0 0",
          maxWidth: "none",
          width: "100%",
          maxHeight: "100%",
          overflow: "hidden",
        },
});

type ModalOverlayProps = PropsWithChildren<{
  disableRestoreFocus?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs" | false;
  onExited?: () => void;
  onClose?: () => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  open: boolean;
  paper?: boolean;
  scroll?: "body" | "paper";
  TransitionComponent?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
}>;

export default function ModalOverlay({ paper = true, ...props }: ModalOverlayProps) {
  const { backdropRootClass, ...dialogClasses } = useStyles({ paper });
  const backdropProps = {
    classes: {
      root: backdropRootClass,
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <MuiDialog disablePortal={true} classes={dialogClasses} BackdropProps={backdropProps} {...props} />
    </ThemeProvider>
  );
}
