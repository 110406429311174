import React from "react";

import {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
} from "react-device-detect";

export type PlatformType = {
  isAndroid: boolean;
  isIOS: boolean;
  isMacOs: boolean;
  isMobile: boolean;
  isMobileOnly: boolean;
  isSafari: boolean;
  isIE: boolean;
  isFirefox: boolean;
  isTablet: boolean;
  isWindows: boolean;
  osVersion: string;
};

export default React.createContext({
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
} as PlatformType);

export {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
} from "react-device-detect";
