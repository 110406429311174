import React from "react";
import { FontType } from "./constants";
import { ArticleMetaType, DefaultMetaType, MetaType } from "modules/meta/types";
import { Helmet } from "react-helmet";
import { metaInfo } from "./MetaInfo";
import { metaIcons } from "./MetaIcons";
import { metaPreloads } from "./MetaPreloads";
import { metaSchema } from "./MetaSchema";
import { metaCustom } from "./MetaCustom";

type MetaProps = {
  defaultMeta: DefaultMetaType;
  lang?: string;
  location: any;
  meta: MetaType | ArticleMetaType;
  preloadFonts?: FontType[];
  preloadImage?: string[] | null;
  preloadDesktopImage?: string[] | null;
  preloadMobileImage?: string[] | null;
  schemaMarkup?: any;
  twitterEnabled?: boolean;
};

const Meta: React.FC<MetaProps> = ({
  defaultMeta,
  lang = "en-GB",
  location,
  meta,
  preloadImage,
  preloadDesktopImage,
  preloadMobileImage,
  preloadFonts,
  schemaMarkup,
  twitterEnabled,
}) => {
  /* Search Engine Visibility */
  const disableIndexing = defaultMeta.search_engine_visibility ? defaultMeta.search_engine_visibility : false;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
      >
        {disableIndexing && <meta name="robots" content="noindex, nofollow" />}
        {metaInfo({ defaultMeta, location, meta, twitterEnabled })}
        {metaIcons()}
        {metaPreloads({ preloadFonts, preloadImage, preloadDesktopImage, preloadMobileImage })}
        {metaSchema({ schemaMarkup })}
        {metaCustom({ defaultMeta })}
      </Helmet>
    </>
  );
};

export default React.memo(Meta);
