export type PageReferenceProps = {
  genericPageUrl?: string;
  newsListUrl?: string;
  newsPageUrl?: string;
  scrListPageUrl?: string;
  acpListPageUrl?: string;
  atoListPageUrl?: string;
  contactPageUrl?: string;
  faqsPageUrl?: string;
  publicationsListPageUrl?: string;
  resourceHubListUrl?: string;
  resourceHubPageUrl?: string;
  categorisation: {
    content_type: {
      slug?: string;
    }[];
  };
};

export const usePageReference = (page_link: PageReferenceProps) => {
  const pageNameKey = page_link !== null && page_link !== undefined && Object?.keys(page_link)[1];
  switch (pageNameKey) {
    case "genericPageUrl":
      return page_link?.genericPageUrl;
    case "newsListUrl":
      return page_link?.newsListUrl;
    case "newsPageUrl":
      return page_link?.newsPageUrl;
    case "scrListPageUrl":
      return page_link?.scrListPageUrl;
    case "acpListPageUrl":
      return page_link?.acpListPageUrl;
    case "contactPageUrl":
      return page_link?.contactPageUrl;
    case "faqsPageUrl":
      return page_link?.faqsPageUrl;
    case "publicationsListPageUrl":
      return page_link?.publicationsListPageUrl;
    case "atoListPageUrl":
      return page_link?.atoListPageUrl;
    case "resourceHubListUrl":
      return page_link?.resourceHubListUrl;
    case "resourceHubPageUrl":
      return `/resource-hub/${page_link?.categorisation?.content_type[0]?.slug}/${page_link?.resourceHubPageUrl}`;
    default:
      return "/";
  }
};
