import React from "react";
import clsx from "clsx";

import { LinkSetType } from "../Megamenu";
import ChevronSVG from "modules/theme/icons/chevrons/chevron.svg";
import { htmlSafe, wrapRegisteredSymbol } from "modules/utils";

type CustomLinkProps = {
  className?: string;
  deepestLevel?: LinkSetType | null | boolean;
  label: string;
  url: string;
};

const CustomLink: React.FC<CustomLinkProps> = ({ className, deepestLevel, label, url }) => {
  const classesName = clsx(className);
  const safeLabel = htmlSafe(wrapRegisteredSymbol(label));
  return (
    <li className={classesName === "" ? null : classesName}>
      <a href={url} onMouseDown={(event) => event.preventDefault()} target="_blank">
        {safeLabel}
        {deepestLevel && <ChevronSVG />}
      </a>
    </li>
  );
};

export default CustomLink;
