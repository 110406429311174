import React from "react";

import { usePageReference } from "modules/unions/usePageReference";
import { LinkSetType } from "../Megamenu";
import CustomLink from "./CustomLink";
import LinkSet from "./LinkSet";
import PageLink from "./PageLink";

type NavigationItemProps = {
  className?: string;
  closeMenuHandler: () => void;
  deepestLevel?: LinkSetType | null | boolean;
  forwardedDropDownRef?: React.Ref<HTMLButtonElement>;
  onClick?: any;
  isOpen?: boolean;
  isMobile: boolean;
  item: any;
  megaMenuColour?: string;
  topLevel?: boolean;
};

const NavigationItem: React.FC<NavigationItemProps> = ({
  children,
  className,
  closeMenuHandler,
  deepestLevel,
  forwardedDropDownRef,
  onClick,
  isOpen,
  isMobile,
  item,
  megaMenuColour,
  topLevel,
}) => {
  if (item.custom_link) {
    return (
      <CustomLink
        className={className}
        deepestLevel={deepestLevel}
        label={item.custom_link.link.title}
        url={item.custom_link.link.href}
      />
    );
  }
  if (item.page_link) {
    return (
      <PageLink
        className={className}
        closeMenuHandler={closeMenuHandler}
        deepestLevel={deepestLevel}
        label={item.page_link.title}
        megaMenuColour={megaMenuColour?.replace(/\s/g, "")}
        slug={usePageReference(item.page_link.reference[0])}
        topLevel={topLevel}
      />
    );
  }
  if (item.link_set) {
    return (
      <LinkSet
        className={className}
        forwardedDropDownRef={forwardedDropDownRef}
        label={item.link_set.title}
        linkedPages={item.link_set?.reference[0]}
        isMobile={isMobile}
        isOpen={!!isOpen}
        megaMenuColour={megaMenuColour?.replace(/\s/g, "")}
        onClick={onClick}
        topLevel={topLevel}
      >
        {children}
      </LinkSet>
    );
  }
  return null;
};

export default NavigationItem;
