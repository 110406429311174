import React from "react";

import * as styles from "./styles.module.scss";

type TaglineProps = {};

const Tagline: React.FC<TaglineProps> = () => {
  return (
    <p className={styles.tagline}>
      <span>Powering </span>
      <span>Best </span>
      <span>Practice</span>
    </p>
  );
};

export default Tagline;
