import React, { useEffect } from "react";
import { setupPlatform } from "./helpers";
import {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
  PlatformType,
} from "modules/platform/context";

import Header from "components/Header";
import Modals from "modules/modals";
import Footer from "components/Footer";

const platform: PlatformType = {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
};

type LayoutProps = {
  children: React.ReactNode;
  forwardedRef?: React.Ref<HTMLElement>;
};
const Layout: React.FC<LayoutProps> = ({ children, forwardedRef }) => {
  useEffect(() => {
    setupPlatform(platform);
  }, []);
  return (
    <>
      <Header forwardedRef={forwardedRef ? forwardedRef : null} />
      <main>{children}</main>
      <Footer />
      <Modals />
    </>
  );
};

export default Layout;
