import React, { useRef, useState } from "react";
import clsx from "clsx";

import * as Button from "components/Button";
import Menu from "./Menu";

import * as styles from "./styles.module.scss";

type MenuColumnProps = {
  items: any;
  title: string;
};

const MenuColumn: React.FC<MenuColumnProps> = ({ items, title }) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [height, setHeight] = useState("0px");
  const ref = useRef<HTMLUListElement>(null);

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
    setHeight(toggleMenu ? "0px" : `${ref.current?.scrollHeight}px`);
  };

  const className = clsx(styles.menuColumn, toggleMenu && styles.open);
  return (
    <div className={className}>
      <div>
        <h6>{title}</h6>
        <Button.Generic
          ariaLabel={toggleMenu ? `Open ${title} menu` : `Close ${title} menu`}
          onClick={handleToggleMenu}
        >
          <span></span>
        </Button.Generic>
      </div>
      <Menu forwardedRef={ref} height={height} items={items} />
    </div>
  );
};

export default MenuColumn;
