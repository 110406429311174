import React from "react";

import YoutubeSVG from "modules/theme/icons/social/youtube.svg";
import TwitterSVG from "modules/theme/icons/social/twitter.svg";
import FacebookSVG from "modules/theme/icons/social/facebook.svg";
import LinkedinSVG from "modules/theme/icons/social/linkedin.svg";
import * as Button from "components/Button";

import * as styles from "./styles.module.scss";
import { useDataLayer } from "modules/gtm";

type SocialProps = {
  linkedin?: string;
  facebook?: string;
  twitter?: string;
  youtube?: string;
};

const Social: React.FC<SocialProps> = ({ linkedin, facebook, twitter, youtube }) => {
  return linkedin || facebook || twitter || youtube ? (
    <ul className={styles.social}>
      {youtube && (
        <li>
          <Button.CustomLink
            ariaLabel="Youtube"
            href={youtube}
            onClick={() => {
              useDataLayer({ action: "YouTube Click", category: "Social Media Profiles", label: youtube });
            }}
          >
            <YoutubeSVG />
          </Button.CustomLink>
        </li>
      )}
      {twitter && (
        <li>
          <Button.CustomLink
            ariaLabel="Twitter"
            href={twitter}
            onClick={() => {
              useDataLayer({ action: "Twitter Click", category: "Social Media Profiles", label: twitter });
            }}
          >
            <TwitterSVG />
          </Button.CustomLink>
        </li>
      )}
      {linkedin && (
        <li>
          <Button.CustomLink
            ariaLabel="Linkedin"
            href={linkedin}
            onClick={() => {
              useDataLayer({ action: "Linkedin Click", category: "Social Media Profiles", label: linkedin });
            }}
          >
            <LinkedinSVG />
          </Button.CustomLink>
        </li>
      )}
      {facebook && (
        <li>
          <Button.CustomLink
            ariaLabel="Facebook"
            href={facebook}
            onClick={() => {
              useDataLayer({ action: "Facebook Click", category: "Social Media Profiles", label: facebook });
            }}
          >
            <FacebookSVG />
          </Button.CustomLink>
        </li>
      )}
    </ul>
  ) : null;
};

export default Social;
