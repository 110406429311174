import React from "react";
import { Link } from "gatsby";
import { useMediaPredicate } from "react-media-hook";
import { usePageReference } from "modules/unions/usePageReference";
import { htmlSafe, wrapRegisteredSymbol } from "modules/utils";
import { ButtonTarget } from "components/Button/constants";

type MenuProps = {
  items: any;
  forwardedRef?: React.RefObject<HTMLUListElement>;
  height?: string;
};

const Menu: React.FC<MenuProps> = ({ height, items, forwardedRef }) => {
  const isMobile = useMediaPredicate("(max-width: 767px)");
  return isMobile ? (
    <ul ref={forwardedRef} style={{ maxHeight: `${height}` }}>
      {items.map((item: any, key: any) => (
        <li key={key}>
          {item.custom_link && (
            <a
              href={item.custom_link.link.href}
              onMouseDown={(event) => event.preventDefault()}
              target={ButtonTarget.BLANK}
            >
              {htmlSafe(wrapRegisteredSymbol(item.custom_link.link.title))}
            </a>
          )}
          {item.page_link && (
            <Link
              className="link"
              to={item.page_link && usePageReference(item?.page_link?.reference[0])}
              onMouseDown={(event: any) => event.preventDefault()}
            >
              {htmlSafe(wrapRegisteredSymbol(item.page_link.title))}
            </Link>
          )}
        </li>
      ))}
    </ul>
  ) : (
    <ul>
      {items.map((item: any, key: any) => (
        <li key={key}>
          {item.custom_link && (
            <a
              href={item.custom_link.link.href}
              onMouseDown={(event) => event.preventDefault()}
              target={ButtonTarget.BLANK}
            >
              {htmlSafe(wrapRegisteredSymbol(item.custom_link.link.title))}
            </a>
          )}
          {item.page_link && (
            <Link
              className="link"
              to={item.page_link && usePageReference(item.page_link.reference[0])}
              onMouseDown={(event: any) => event.preventDefault()}
            >
              {htmlSafe(wrapRegisteredSymbol(item.page_link.title))}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Menu;
