type useDataLayerProps = {
  action: string;
  category: string;
  label: string;
};

export const useDataLayer = ({ action, category, label }: useDataLayerProps) => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  return (window as any).dataLayer.push({
    event: "customEvent",
    customEventCategory: category,
    customEventAction: action,
    customEventLabel: label,
  });
};
