import React from "react";
import { FontType } from "./constants";

type MetaPreloadsProps = {
  preloadFonts?: FontType[];
  preloadImage?: string[] | null;
  preloadDesktopImage?: string[] | null;
  preloadMobileImage?: string[] | null;
};

export const metaPreloads = ({
  preloadImage,
  preloadDesktopImage,
  preloadMobileImage,
  preloadFonts = [
    FontType.PRIMARY_REGULAR,
    FontType.PRIMARY_SEMIBOLD,
    FontType.SECONDARY_SEMIBOLD,
    FontType.SECONDARY_LIGHT,
  ],
}: MetaPreloadsProps) => {
  return [
    preloadFonts?.map((font, key) => (
      <link key={`${key}-preload-font`} rel="preload" as="font" href={font} type="font/woff2" crossOrigin="anonymous" />
    )),
    preloadDesktopImage !== null &&
      preloadDesktopImage?.map((font, key) => (
        <link key={`preload-desktop-img-${key}`} rel="preload" href={font} as="image" media="(min-width: 361px)" />
      )),
    preloadMobileImage !== null &&
      preloadMobileImage?.map((font, key) => (
        <link key={`preload-mobile-img-${key}`} rel="preload" href={font} as="image" media="(max-width: 360px)" />
      )),
    preloadImage !== null &&
      preloadImage?.map((font, key) => <link key={`preload-img-${key}`} rel="preload" href={font} as="image" />),
    <link key="image-dns" rel="dns-prefetch" href="//eu-images.contentstack.com" />,
    <link key="image-pre" rel="preconnect" href="//eu-images.contentstack.com" crossOrigin="anonymous" />,
    <link key="cookie-control-dns" rel="dns-prefetch" href="//cc.cdn.civiccomputing.com" />,
    <link key="cookie-control-pre" rel="preconnect" href="//cc.cdn.civiccomputing.com" crossOrigin="anonymous" />,
    <link key="ga-dns" rel="dns-prefetch" href="//www.google-analytics.com" />,
    <link key="ga-pre" rel="preconnect" href="//www.google-analytics.com" crossOrigin="anonymous" />,
    <link key="gtm-dns" rel="dns-prefetch" href="//www.googletagmanager.com" />,
    <link key="gtm-pre" rel="preconnect" href="//www.googletagmanager.com" crossOrigin="anonymous" />,
    <link key="google-dns" rel="dns-prefetch" href="//www.google.com" />,
    <link key="google-pre" rel="preconnect" href="//www.google.com" crossOrigin="anonymous" />,
    <link key="gstatic-dns" rel="dns-prefetch" href="//www.gstatic.com" />,
    <link key="gstatic-pre" rel="preconnect" href="//www.gstatic.com" crossOrigin="anonymous" />,
  ];
};
