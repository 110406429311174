import React from "react";
import clsx from "clsx";

import * as styles from "./styles.module.scss";
import * as newsletterStyles from "../styles.module.scss";
import * as questionStyles from "components/Question/styles.module.scss";
import * as inputStyles from "components/Inputs/Input/Primitive/styles.module.scss";
import * as labelStyles from "components/Label/styles.module.scss";

const NewsletterSkeleton = () => {
  return (
    <div className={clsx(newsletterStyles.newsletter, styles.newsletterSkeleton)}>
      {/* <form>
        <div></div>
        <div className={questionStyles.question}>
          <div className={clsx(inputStyles.input, inputStyles.primary)}>
            <input type="text" />
            <label className={clsx(labelStyles.label, labelStyles.stickyLabel)}>Email address for newsletter*</label>
          </div>
        </div>
        <div className={questionStyles.question}>
          <div className={clsx(inputStyles.input, inputStyles.primary)}>
            <input type="text" />
            <label className={clsx(labelStyles.label, labelStyles.stickyLabel)}>First name*</label>
          </div>
        </div>
        <div>
          <small>
            This site is protected by reCAPTCHA and the Google{" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              {" "}
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="https://policies.google.com/terms" target="_blank">
              {" "}
              Terms of Service
            </a>{" "}
            apply.
          </small>
          <button className={`btn Aqua`}>
            <span>Signup</span>
            <svg
              className="buttonChevron"
              xmlns="http://www.w3.org/2000/svg"
              width="5.91"
              height="15.027"
              viewBox="0 0 5.91 15.027"
            >
              <path
                d="M2309.9,418.441l-3.406-6.247a1.252,1.252,0,0,0-2.2,1.2l3.087,5.662-3.087,5.662a1.252,1.252,0,1,0,2.2,1.2l3.406-6.247a1.313,1.313,0,0,0,0-1.227Z"
                transform="translate(-2304.147 -411.541)"
                fill="#ebebed"
              />
            </svg>
            <svg className="buttonSlice" preserveAspectRatio="none" focusable="false" viewBox="0 0 73.5037 123.2335">
              <path d="M52.7357,0.0705L0,0v123.2335c6.061-0.757,11.5931-4.0227,15.1647-9.1064l56.0161-89.2549 C78.2703,13.5806,68.2817,0.1635,52.7357,0.0705z"></path>
            </svg>
          </button>
        </div>
      </form>
      <small>
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy" target="_blank">
          {" "}
          Privacy Policy
        </a>{" "}
        and{" "}
        <a href="https://policies.google.com/terms" target="_blank">
          {" "}
          Terms of Service
        </a>{" "}
        apply.
      </small> */}
    </div>
  );
};

export default NewsletterSkeleton;
