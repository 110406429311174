import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { modalActionShow, modalActionHide } from "../actions";
import { Dispatch } from "modules/redux/store";
import { ModalType } from "../constants";
import { ModalFormProps } from "components/Modal/Form/types";
import { ModalInfoProps } from "components/Modal/Info";

export type Modal =
  | ({
      type: ModalType;
    } & { type: ModalType.FORM } & Omit<ModalFormProps, "destroyModal" | "hideModal" | "id" | "open">)
  | ({ type: ModalType.INFO } & Omit<ModalInfoProps, "destroyModal" | "hideModal" | "id" | "open">);

const useModalAction = () => {
  const dispatch: Dispatch = useDispatch();
  return {
    modalHide: useCallback(
      (id: string) => {
        dispatch(modalActionHide(id));
      },
      [dispatch]
    ),
    modalShow: useCallback(
      (modal: Modal) => {
        return dispatch(modalActionShow(modal));
      },
      [dispatch]
    ),
  };
};

export default useModalAction;
