import React from "react";

type MetaSchemaProps = {
  schemaMarkup: any;
};

export const metaSchema = ({ schemaMarkup }: MetaSchemaProps) => {
  return (
    <script key="schema" type="application/ld+json">
      {JSON.stringify(schemaMarkup)}
    </script>
  );
};
