import React, { useEffect, useState } from "react";
import { useId } from "react-id-generator";
import { useStaticQuery, graphql } from "gatsby";
import clsx from "clsx";
import NavigationItem from "./NavigationItem";
import { LinkSetType } from "./Megamenu";

type NavigationProps = {
  activeMenuItem?: string;
  children?: React.ReactNode;
  closeMegaMenu?: boolean;
  closeMenuHandler: () => void;
  closeMobileMenu: boolean;
  finalLevelActive?: boolean;
  forwardedDropDownRef?: (el: any) => void;
  forwardedMegaMenuRef?: React.Ref<HTMLDivElement>;
  handleSetLevelOne?: (menuItem: any, id: string) => void;
  handleSetLevelTwo?: (menuItem: any, id: string) => void;
  handleSetLevelThree?: (menuItem: any, id: string) => void;
  isMobile: boolean;
  levelOneActive: LinkSetType | null;
  levelTwoActive: LinkSetType | null;
  levelThreeActive: LinkSetType | null;
  openMegaMenu?: boolean;
  openMobileMenu: boolean;
  megaMenuActiveIds: string[];
};

const Navigation: React.FC<NavigationProps> = ({
  activeMenuItem,
  children,
  closeMegaMenu,
  closeMenuHandler,
  closeMobileMenu,
  finalLevelActive,
  forwardedDropDownRef,
  forwardedMegaMenuRef,
  handleSetLevelOne,
  handleSetLevelTwo,
  handleSetLevelThree,
  isMobile,
  levelOneActive,
  levelTwoActive,
  levelThreeActive,
  openMegaMenu,
  openMobileMenu,
  megaMenuActiveIds,
}) => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      contentstackNavigation {
        ...navigation
      }
    }
  `);

  const {
    contentstackNavigation: { items },
  } = data;

  const className = clsx(
    "navigation",
    openMegaMenu && "openMegaMenu",
    openMobileMenu && "openMobileMenu",
    closeMobileMenu && "closeMobileMenu"
  );

  const [isMegamenuDisplayed, setMegamenuDisplayed] = useState(false);
  const [Megamenu, setMegamenu] = useState<any>(null);
  const loadMegamenuComponent = async () => {
    const loadResult = await import("components/Header/Navigation/Megamenu");
    setMegamenu(() => loadResult.default);
  };
  useEffect(() => {
    if (!levelOneActive) return;
    if (!isMegamenuDisplayed) {
      setMegamenuDisplayed(true);
      loadMegamenuComponent();
    }
  }, [levelOneActive]);

  return (
    <React.Fragment>
      <nav className={className}>
        <ul>
          {items &&
            items.map((item: any) => {
              const [menuId] = useId(1, "menu-item-");
              return (
                <NavigationItem
                  closeMenuHandler={closeMenuHandler}
                  forwardedDropDownRef={forwardedDropDownRef}
                  isOpen={activeMenuItem === menuId}
                  isMobile={isMobile}
                  item={item}
                  key={menuId}
                  onClick={() => {
                    if (handleSetLevelOne) handleSetLevelOne(item.link_set, menuId);
                  }}
                  topLevel={true}
                />
              );
            })}
        </ul>
        {levelOneActive && isMegamenuDisplayed && Megamenu ? (
          <Megamenu
            closeMegaMenu={closeMegaMenu}
            closeMenuHandler={closeMenuHandler}
            finalLevelActive={finalLevelActive}
            forwardedMegaMenuRef={forwardedMegaMenuRef}
            handleSetLevelTwo={handleSetLevelTwo}
            handleSetLevelThree={handleSetLevelThree}
            isMobile={isMobile}
            levelOneActive={levelOneActive}
            levelTwoActive={levelTwoActive}
            levelThreeActive={levelThreeActive}
            promo={levelOneActive.reference[0]?.promo}
            sections={levelOneActive.reference[0]?.sections}
            megaMenuActiveIds={megaMenuActiveIds}
          />
        ) : null}
        {children}
      </nav>
    </React.Fragment>
  );
};

export default Navigation;
