import React from "react";
import clsx from "clsx";
import { useLocation } from "@reach/router";

import { flattenObject, htmlSafe, wrapRegisteredSymbol } from "modules/utils";
import ChevronSVG from "modules/theme/icons/chevrons/animated-chevron.svg";
import MegaMenuChevronSVG from "modules/theme/icons/chevrons/chevron.svg";
import * as Button from "components/Button";

type LinkSetProps = {
  className?: string;
  forwardedDropDownRef?: React.Ref<HTMLButtonElement>;
  isOpen: boolean;
  isMobile?: boolean;
  label: string;
  linkedPages: any;
  megaMenuColour?: string;
  onClick: any;
  topLevel?: boolean;
};

const LinkSet: React.FC<LinkSetProps> = ({
  className,
  children,
  forwardedDropDownRef,
  isOpen,
  isMobile,
  label,
  linkedPages,
  megaMenuColour,
  onClick,
  topLevel,
}) => {
  const location = useLocation();

  const allValues = Object.entries(flattenObject(linkedPages))
    .filter((i) => i[1] !== null && i[1] !== "")
    .map((i) => i[1]);

  const containsPathname = allValues.find((i: any) => i === location.pathname);
  const isResourceHub =
    (allValues.includes("/resource-hub/") || allValues.includes("/resource-hub")) &&
    /^\/resource-hub/.test(location.pathname);
  const isNewsPage =
    (allValues.includes("/about-axelos/news/") || allValues.includes("/about-axelos/news")) &&
    /^\/about-axelos\/news/.test(location.pathname);

  const linksetClassName = clsx(
    topLevel && "dropdown topLevel",
    (containsPathname || isResourceHub || isNewsPage) && "isActive",
    isOpen && "isOpen",
    megaMenuColour && `${megaMenuColour}`,
    className
  );

  const linkSetChevron = () => {
    if (topLevel) {
      return !isMobile ? <ChevronSVG /> : <MegaMenuChevronSVG />;
    }
    return <MegaMenuChevronSVG />;
  };

  const safeLabel = htmlSafe(wrapRegisteredSymbol(label));

  return (
    <li className={linksetClassName === "" ? null : linksetClassName}>
      <Button.Generic label={safeLabel} onClick={onClick} forwardedRef={forwardedDropDownRef}>
        {linkSetChevron()}
      </Button.Generic>
      {children}
    </li>
  );
};

export default LinkSet;
