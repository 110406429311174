import React from "react";
import clsx from "clsx";
import { useLocation } from "@reach/router";

import { Link } from "gatsby";
import { LinkSetType } from "../Megamenu";
import ChevronSVG from "modules/theme/icons/chevrons/chevron.svg";
import { htmlSafe, wrapRegisteredSymbol } from "modules/utils";

export type PageLinkProps = {
  className?: string;
  closeMenuHandler: () => void;
  deepestLevel?: LinkSetType | null | boolean;
  label: string;
  megaMenuColour?: string;
  slug?: string;
  topLevel?: boolean;
};

const PageLink: React.FC<PageLinkProps> = ({
  className,
  closeMenuHandler,
  deepestLevel,
  label,
  megaMenuColour,
  slug,
  topLevel,
}) => {
  const location = useLocation();
  const isResourceHub = topLevel && slug && /^\/resource-hub/.test(slug) && /^\/resource-hub/.test(location.pathname);
  const isNewsPage =
    topLevel && slug && /^\/about-axelos\/news/.test(slug) && /^\/about-axelos\/news/.test(location.pathname);

  const classNames = clsx(
    className,
    topLevel && "topLevel",
    (isResourceHub || isNewsPage || location.pathname === slug) && "isActive",
    megaMenuColour && `${megaMenuColour}`
  );

  const closeActivePageMenu = () => {
    location.pathname === slug && closeMenuHandler();
  };

  const safeLabel = htmlSafe(wrapRegisteredSymbol(label));

  return (
    <li className={classNames === "" ? null : classNames}>
      <Link
        onMouseDown={(event: any) => event.preventDefault()}
        className="link"
        to={slug ? slug : "/"}
        onClick={closeActivePageMenu}
      >
        {safeLabel}
        {deepestLevel && <ChevronSVG />}
      </Link>
    </li>
  );
};

export default PageLink;
