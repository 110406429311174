import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import loadable from "@loadable/component";

import LogoSVG from "modules/theme/logos/axelos.svg";
import Tagline from "./Tagline";
import MenuColumn from "./MenuColumn";
import Social from "./Social";
import ArtworkSVG from "modules/theme/artwork/footer.svg";
import NewsletterSkeleton from "components/Newsletter/Skeleton";
import { isMobile } from "modules/platform/context";

import * as styles from "./styles.module.scss";

type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      contentstackFooter {
        ...footer
      }
    }
  `);

  const [showNewsletter, setShowNewsletter] = useState(false);
  const Newsletter = loadable(() => import("components/Newsletter"), {
    fallback: <NewsletterSkeleton />,
  });

  useEffect(() => {
    if (showNewsletter) return;
    document.addEventListener("keydown", () => {
      setShowNewsletter(true);
    });
    !isMobile
      ? document.addEventListener("mousemove", () => {
          setShowNewsletter(true);
        })
      : document.addEventListener("touchmove", () => {
          setShowNewsletter(true);
        });
  }, []);
  return (
    <footer className={styles.footer}>
      <ArtworkSVG />
      <div className="container">
        <div>
          <Link
            aria-label="Axelos Homepage"
            className="link"
            onMouseDown={(event: any) => event.preventDefault()}
            to="/"
          >
            <LogoSVG />
          </Link>
          <Tagline />
        </div>
        {showNewsletter ? <Newsletter /> : <NewsletterSkeleton />}
        {(data.contentstackFooter.column_1_items ||
          data.contentstackFooter.column_2_items ||
          data.contentstackFooter.column_3_items ||
          data.contentstackFooter.column_4_items) && (
          <nav>
            <MenuColumn items={data.contentstackFooter.column_1_items} title={data.contentstackFooter.column_1_title} />
            <MenuColumn items={data.contentstackFooter.column_2_items} title={data.contentstackFooter.column_2_title} />
            <MenuColumn items={data.contentstackFooter.column_3_items} title={data.contentstackFooter.column_3_title} />
            <MenuColumn items={data.contentstackFooter.column_4_items} title={data.contentstackFooter.column_4_title} />
          </nav>
        )}
        <div>
          <Social
            linkedin={data.contentstackFooter.social.linkedin_url}
            facebook={data.contentstackFooter.social.facebook_url}
            twitter={data.contentstackFooter.social.twitter_url}
            youtube={data.contentstackFooter.social.youtube_url}
          />
          <p>Copyright © {new Date().getFullYear()} Axelos Ltd. All rights reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
