import React from "react";

import BackArrowSVG from "modules/theme/icons/chevrons/back-arrow.svg";
import * as Button from "components/Button";
import { LinkSetType } from "../Megamenu";

import * as styles from "./styles.module.scss";

type PreviousMenuButtonProps = {
  handleMobileMenuBackClick: () => void;
  levelOneActive: LinkSetType | null;
  levelTwoActive: LinkSetType | null;
  levelThreeActive: LinkSetType | null;
};

const PreviousMenuButton: React.FC<PreviousMenuButtonProps> = ({
  handleMobileMenuBackClick,
  levelOneActive,
  levelTwoActive,
  levelThreeActive,
}) => {
  return levelOneActive || levelTwoActive || levelThreeActive ? (
    <Button.Generic
      ariaLabel="Open Previous Menu"
      className={styles.prevMenuButton}
      onClick={handleMobileMenuBackClick}
    >
      <BackArrowSVG />
    </Button.Generic>
  ) : null;
};

export default PreviousMenuButton;
