import React from "react";

export const metaIcons = () => {
  return [
    <link key="shortcut-icon" rel="shortcut icon" href="/images/favicon/favicon.ico" />,
    <link key="icon-180" rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png" />,
    <link key="icon-32" rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />,
    <link key="icon-16" rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />,
    <link key="mask-icon" rel="mask-icon" href="/assets/images/favicon/safari-pinned-tab.svg" color="#1c222f" />,
    <link key="manifest" rel="manifest" href="/manifest.webmanifest" crossOrigin="anonymous" />,
    <meta key="theme-color" name="theme-color" content="#6DE2E2" />,
  ];
};
