import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "modules/redux/store";

import { modalActionDestroy, modalActionHide } from "./actions";
import { ModalFormProps } from "components/Modal/Form/types";
import { ModalType } from "./constants";
import { ModalInfoProps } from "components/Modal/Info";
import * as Modal from "components/Modal";

import * as styles from "./styles.module.scss";

type RenderModalProps = {
  type: ModalType;
} & (({ type: ModalType.FORM } & ModalFormProps) | ({ type: ModalType.INFO } & ModalInfoProps));

const renderModal = ({ type, ...props }: RenderModalProps) => {
  switch (type) {
    case ModalType.FORM:
      return <Modal.Form key={props.id} {...(props as ModalFormProps)} />;
    case ModalType.INFO:
      return <Modal.Info key={props.id} {...(props as ModalInfoProps)} />;

    default:
      return null;
  }
};

type ModalsProps = {};

const Modals: React.FunctionComponent<ModalsProps> = () => {
  const modals = useSelector((state: RootState) => state.modals);
  const dispatch: Dispatch = useDispatch();
  return (
    <div className={styles["modals"]}>
      {modals.length > 0 &&
        modals.map((modal) =>
          renderModal({
            destroyModal: () => dispatch(modalActionDestroy(modal.id)),
            hideModal: () => dispatch(modalActionHide(modal.id)),
            ...modal,
          })
        )}
    </div>
  );
};

export default React.memo(Modals);
