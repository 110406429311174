// @ts-nocheck // TODO fix ref ts error
import React, { useEffect, useRef, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useMediaPredicate } from "react-media-hook";

import LogoSVG from "modules/theme/logos/axelos.svg";
import * as Button from "components/Button";
import { ButtonStyle, ButtonTarget } from "components/Button/constants";
import Navigation from "./Navigation";
import BurgerMenuButton from "./Navigation/BurgerMenuButton";
import PreviousMenuButton from "./Navigation/PreviousMenuButton";
import { LinkSetType } from "./Navigation/Megamenu";

type HeaderProps = {
  forwardedRef?: React.Ref<HTMLElement>;
};

const Header: React.FC<HeaderProps> = ({ forwardedRef }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      contentstackHeader {
        sign_in_button {
          href
          title
        }
        my_axelos_url {
          href
          title
        }
      }
    }
  `);

  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [closeMobileMenu, setCloseMobileMenu] = useState(false);
  const [openMegaMenu, setOpenMegaMenu] = useState(false);
  const [closeMegaMenu, setCloseMegaMenu] = useState(false);
  const isMobile = useMediaPredicate("(max-width: 767px)");
  const [mainActiveMenuItemId, setMainActiveMenuItemId] = useState("");
  const [megaMenuActiveIds, setMegaMenuActiveIds] = useState<string[]>([]);
  const [levelOneActive, setLevelOneActive] = useState<LinkSetType | null>(null);
  const [levelTwoActive, setLevelTwoActive] = useState<LinkSetType | null>(null);
  const [levelThreeActive, setLevelThreeActive] = useState<LinkSetType | null>(null);
  const [finalLevelActive, setFinalLevelActive] = useState(false);
  // const [isSiteMessageEnable, setIsSiteMessageEnable] = useState(data.contentstackHeader.enable_site_flash_message);
  const megaMenuRef = useRef<HTMLDivElement>(null);
  const dropdDownRefs = useRef<HTMLElement[]>([]);
  dropdDownRefs.current = [];

  const addToRefs = (el: any) => {
    if (el && !dropdDownRefs.current.includes(el)) {
      dropdDownRefs.current.push(el);
    }
  };

  const finalLevel = (newItems: LinkSetType) => {
    if (isMobile && GetFirstSetLink(newItems) === null) {
      setFinalLevelActive(true);
    } else {
      setFinalLevelActive(false);
    }
  };

  const handleSetLevelOneMobile = (newItems: LinkSetType, index: any) => {
    if (levelTwoActive || levelThreeActive) {
      setLevelTwoActive(null);
      setLevelThreeActive(null);
    }
    setLevelOneActive(newItems);
    finalLevel(newItems.reference[0]);
  };

  const handleSetLevelTwoMobile = (newItems: LinkSetType, index: any) => {
    setLevelTwoActive(newItems);
    const hasFinalLevel = GetFirstSetLink(newItems);
    if (!hasFinalLevel) setLevelThreeActive(null);
    finalLevel(newItems);
  };

  const handleSetLevelOne = (newItems: LinkSetType, id: string) => {
    if (levelTwoActive || levelThreeActive) {
      setLevelTwoActive(null);
      setLevelThreeActive(null);
    }
    setMainActiveMenuItemId(id);
    setLevelOneActive(newItems);
    const levelTwoSetLink = GetFirstSetLink(newItems.reference[0] as any);
    if (levelTwoSetLink && levelTwoSetLink.link_set) {
      setMegaMenuActiveIds(levelTwoSetLink.link_set.reference[0]?.id);
      setLevelTwoActive(levelTwoSetLink.link_set);
      const levelThreeSetLink = GetFirstSetLink(levelTwoSetLink.link_set.reference[0]);
      if (levelThreeSetLink && levelThreeSetLink.link_set) {
        setMegaMenuActiveIds((prevIds) => {
          const newId = levelThreeSetLink.link_set.reference[0]?.id;
          return [prevIds, newId];
        });
        setLevelThreeActive(levelThreeSetLink.link_set);
      }
    }
    if (id === mainActiveMenuItemId) {
      setMainActiveMenuItemId("");
      setMegaMenuActiveIds([]);
      setCloseMegaMenu(true);
      setTimeout(() => {
        setCloseMegaMenu(false);
        setLevelOneActive(null);
        setLevelTwoActive(null);
        setLevelThreeActive(null);
      }, 175);
    }
  };

  const handleSetLevelTwo = (newItems: LinkSetType, id: string) => {
    setMegaMenuActiveIds(() => id as any);
    setLevelTwoActive(newItems);
    const levelThreeSetLink = GetFirstSetLink(newItems);
    if (levelThreeSetLink && levelThreeSetLink.link_set) {
      setLevelThreeActive(levelThreeSetLink.link_set);
      setMegaMenuActiveIds((prevIds) => {
        const newId = levelThreeSetLink.link_set.reference[0]?.id;
        return [prevIds, newId];
      });
    } else {
      setLevelThreeActive(null);
    }
  };

  const handleSetLevelThree = (newItems: LinkSetType, id: string) => {
    setMegaMenuActiveIds((prevItems) => {
      if (prevItems.includes(id)) return prevItems;
      return [prevItems[0], id];
    });
    setLevelThreeActive(newItems);
    finalLevel(newItems);
  };

  const GetFirstSetLink = (setLinkItems: LinkSetType) => {
    const { sections } = setLinkItems;
    const section =
      sections &&
      (sections as any)?.find((i: any) => {
        return i.section.items.some((link: any) => link.link_set != null);
      });
    const firstItem = section?.section.items.filter((i: any) => i.link_set);
    return firstItem?.length ? firstItem[0] : null;
  };

  const closeAllLevels = () => {
    setLevelOneActive(null);
    setLevelTwoActive(null);
    setLevelThreeActive(null);
    setMainActiveMenuItemId("");
    setMegaMenuActiveIds([]);
  };

  const closeMegaMenuHandler = () => {
    setCloseMegaMenu(true);
    setTimeout(() => {
      closeAllLevels();
      setCloseMegaMenu(false);
    }, 175);
  };

  const openMobileMenuHandler = () => {
    setOpenMobileMenu(true);
    setToggleMobileMenu(true);
    setCloseMegaMenu(false);
    closeAllLevels();
  };

  const closeMobileMenuHandler = () => {
    setCloseMobileMenu(true);
    setToggleMobileMenu(false);
    document.documentElement.classList.remove("lock-screen");
    setTimeout(() => {
      setOpenMobileMenu(false);
      setCloseMobileMenu(false);
      closeAllLevels();
      if (openMegaMenu) {
        setOpenMegaMenu(false);
        setCloseMegaMenu(false);
      }
    }, 175);
  };

  const handleMobileMenuClick = () => {
    !openMobileMenu ? openMobileMenuHandler() : closeMobileMenuHandler();
  };

  const handleMobileMenuBackClick = () => {
    setFinalLevelActive(false);
    if (levelOneActive) {
      setLevelOneActive(null);
    }
    if (levelTwoActive) {
      setLevelOneActive(levelOneActive);
      setLevelTwoActive(null);
    }
    if (levelThreeActive) {
      setLevelTwoActive(levelTwoActive);
      setLevelThreeActive(null);
    }
  };

  useEffect(() => {
    const closeMegaMenuOnScroll = () => {
      !isMobile && closeMegaMenuHandler();
    };
    window.addEventListener("scroll", closeMegaMenuOnScroll);
    return () => {
      window.removeEventListener("scroll", closeMegaMenuOnScroll);
    };
  }, [closeMegaMenu]);

  useEffect(() => {
    const closeMegaMenuOnClickOutside = (event: any) => {
      if (
        !isMobile &&
        !dropdDownRefs.current.some((i: any) => i.contains(event.target)) &&
        megaMenuRef.current &&
        !megaMenuRef.current.contains(event.target)
      ) {
        closeMegaMenuHandler();
      }
    };
    document.addEventListener("mousedown", closeMegaMenuOnClickOutside);
    return () => {
      document.removeEventListener("mousedown", closeMegaMenuOnClickOutside);
    };
  }, [isMobile, megaMenuRef, dropdDownRefs]);

  useEffect(() => {
    const closeMobileMenuOnResize = () => {
      if (!isMobile && openMobileMenu) {
        setToggleMobileMenu(false);
        setOpenMobileMenu(false);
        closeAllLevels();
        document.documentElement.classList.remove("lock-screen");
      }
    };
    window.addEventListener("resize", closeMobileMenuOnResize);
    window.addEventListener("orientationchange", closeMobileMenuOnResize);
    return () => {
      window.removeEventListener("resize", closeMobileMenuOnResize);
      window.removeEventListener("orientationchange", closeMobileMenuOnResize);
    };
  }, [isMobile, openMobileMenu]);

  useEffect(() => {
    if (openMobileMenu) {
      document.documentElement.classList.add("mobile-menu-open");
      document.documentElement.classList.add("lock-screen");
    } else {
      document.documentElement.classList.remove("mobile-menu-open");
      document.documentElement.classList.remove("lock-screen");
    }
  }, [openMobileMenu]);

  return (
    <React.Fragment>
      {/* {isSiteMessageEnable && <div class="siteflashmessage"> {data.contentstackHeader.site_flash_message} </div>} */}

      {/* <header className="header" style={{ top: isSiteMessageEnable ? '42px' : '0' }} ref={forwardedRef}> */}

      {/* <div class="siteflashmessage">
        Exciting news: MyAxelos is transitioning to a brand new membership experience. In preparation for our launch,
        we'll need to implement a few adjustments, therefore, for the week of Monday, 26th February 2024, certain
        MyAxelos functionalities will be temporarily unavailable. These include: Editing of CPD points, Access to
        Digital Badges, Subscription changes, Payment detail updates. Rest assured, access to all other resources and
        content will remain uninterrupted during this period.
        Please visit{" "}
        <a href="https://www.peoplecert.org/membership" target="_blank" rel="noreferrer">
          www.peoplecert.org/membership
        </a>{" "}
        to learn more.
      </div> */}

      <header className="header"  style={{ top: '0' }} ref={forwardedRef}>
        <div className="container">
          <Link
            aria-label="Axelos Homepage"
            className="link"
            onMouseDown={(event) => event.preventDefault()}
            tabIndex={openMobileMenu ? -1 : 0}
            to="/"
          >
            <LogoSVG />
          </Link>
          {!isMobile ? (
            <React.Fragment>
              <Navigation
                activeMenuItem={mainActiveMenuItemId}
                closeMegaMenu={closeMegaMenu}
                closeMenuHandler={closeMegaMenuHandler}
                closeMobileMenu={closeMobileMenu}
                forwardedDropDownRef={addToRefs}
                forwardedMegaMenuRef={megaMenuRef}
                handleSetLevelOne={handleSetLevelOne}
                handleSetLevelTwo={handleSetLevelTwo}
                handleSetLevelThree={handleSetLevelThree}
                levelOneActive={levelOneActive}
                levelTwoActive={levelTwoActive}
                levelThreeActive={levelThreeActive}
                openMegaMenu={openMegaMenu}
                openMobileMenu={openMobileMenu}
                megaMenuActiveIds={megaMenuActiveIds}
              />
              <Button.CustomLink
                href={data.contentstackHeader.sign_in_button.href}
                label={data.contentstackHeader.sign_in_button.title}
                roundedCorner={true}
                style={ButtonStyle.BRAND_AQUA}
                target={ButtonTarget.BLANK}
              />
            </React.Fragment>
          ) : null}
        </div>
      </header>
      {isMobile ? (
        <React.Fragment>
          <PreviousMenuButton
            levelOneActive={levelOneActive}
            levelTwoActive={levelTwoActive}
            levelThreeActive={levelThreeActive}
            handleMobileMenuBackClick={handleMobileMenuBackClick}
          />
          <BurgerMenuButton toggleMobileMenu={toggleMobileMenu} handleMobileMenuClick={handleMobileMenuClick} />
          <Navigation
            closeMegaMenu={closeMegaMenu}
            closeMobileMenu={closeMobileMenu}
            closeMenuHandler={closeMobileMenuHandler}
            finalLevelActive={finalLevelActive}
            forwardedDropDownRef={addToRefs}
            forwardedMegaMenuRef={megaMenuRef}
            handleSetLevelOne={handleSetLevelOneMobile}
            handleSetLevelTwo={handleSetLevelTwoMobile}
            handleSetLevelThree={handleSetLevelThree}
            isMobile={isMobile}
            levelOneActive={levelOneActive}
            levelTwoActive={levelTwoActive}
            levelThreeActive={levelThreeActive}
            openMegaMenu={openMegaMenu}
            openMobileMenu={openMobileMenu}
          >
            <Button.CustomLink
              href={data.contentstackHeader.sign_in_button.href}
              label={data.contentstackHeader.sign_in_button.title}
              roundedCorner={true}
              style={ButtonStyle.BRAND_AQUA}
              target={ButtonTarget.BLANK}
            />
          </Navigation>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default React.memo(Header);
