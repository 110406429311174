import React from "react";

import { ArticleMetaType, DefaultMetaType, MetaType } from "modules/meta/types";
import { objectToQueryString, useDpr } from "modules/utils";
import useSiteMetadata from "modules/siteurl";

type MetaInfoProps = {
  defaultMeta: DefaultMetaType;
  location: any;
  meta: MetaType | ArticleMetaType;
  twitterEnabled?: boolean;
};

export const metaInfo = ({ defaultMeta, location, meta, twitterEnabled }: MetaInfoProps) => {
  /* Image Params */
  const ogParams = {
    width: 1200 * useDpr(),
    height: 627 * useDpr(),
    fit: "crop",
    format: "jpg",
    auto: "webp",
    quality: "60",
  };

  const twitterParams = {
    width: 280 * useDpr(),
    height: 150 * useDpr(),
    fit: "crop",
    format: "jpg",
    auto: "webp",
    quality: "60",
  };
  /* Site URL */
  const { siteUrl } = useSiteMetadata();

  /* Default */
  const defaultTitle = defaultMeta.title;

  /* Standard */
  const windowTitle = meta.window_title ? `${meta.window_title} | Axelos` : defaultTitle;
  const metaDescription = meta.meta_description || defaultMeta.default_meta_description;
  const canonical = meta.canonical ? meta.canonical : `${siteUrl}${location.pathname}`;

  /* Opengraph */
  const ogMetaTitle = meta.opengraph_title ? meta.opengraph_title : windowTitle;
  const ogMetaDescription = meta.opengraph_description ? meta.opengraph_description : metaDescription;
  const ogMetaType = meta.opengraph_type ? meta.opengraph_type : "website";
  const ogMetaUrl = meta.opengraph_url ? meta.opengraph_url : canonical;
  const ogMetaImage =
    meta.opengraph_image && meta.opengraph_image?.url
      ? `${meta.opengraph_image?.url}?${objectToQueryString(ogParams)}`
      : `${defaultMeta.default_opengraph_image.url}?${objectToQueryString(ogParams)}`;

  /* Twitter */
  const twitterMetaTitle = (meta as ArticleMetaType).twitter_title
    ? (meta as ArticleMetaType).twitter_title
    : windowTitle;
  const twitterMetaDescription = (meta as ArticleMetaType).twitter_description
    ? (meta as ArticleMetaType).twitter_description
    : metaDescription;
  const twitterMetaImage =
    (meta as ArticleMetaType).twitter_image && (meta as ArticleMetaType).twitter_image?.url
      ? `${(meta as ArticleMetaType).twitter_image?.url}?${objectToQueryString(twitterParams)}`
      : `${defaultMeta.default_twitter_image.url}?${objectToQueryString(twitterParams)}`;

  return [
    <title key="title">{windowTitle}</title>,
    <meta key="description" name="description" content={metaDescription} />,
    <meta key="og:title" name="og:title" content={ogMetaTitle} />,
    <meta key="og:description" name="og:description" content={ogMetaDescription} />,
    <meta key="og:type" name="og:type" content={ogMetaType} />,
    <meta key="og:url" name="og:url" content={ogMetaUrl} />,
    <meta key="og:image" name="og:image" content={ogMetaImage} />,
    twitterEnabled && <meta key="twitter:card" name="twitter:card" content="summary" />,
    twitterEnabled && <meta key="twitter:title" name="twitter:title" content={twitterMetaTitle} />,
    twitterEnabled && <meta key="twitter:description" name="twitter:description" content={twitterMetaDescription} />,
    twitterEnabled && <meta key="twitter:image" name="twitter:image" content={twitterMetaImage} />,
    <link key="canonical" rel="canonical" href={canonical} />,
  ];
};
